<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div v-if="errors.length > 0">
            <p class="err-txt">{{ errors }}</p>
          </div>
          <div class="popup-content-form-wrap">
            <textarea type="text" name="" id="" placeholder=" 通報内容を入力する" v-model="reportComment"></textarea>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
            <v-btn @click="report" color="#364A81" class="white--text">{{$t("REPORT_COMFIRM_DIALOG_OK_LABEL")}}</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import CommonFilters from '@/common/commonFilters';
import useChat from '@/stores/useChat';
import useLoading from '@/stores/useLoading';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const chatStore = useChat()
    return {
      loadingStore,
      chatStore
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    reportComment : {
      get() {
        return this.chatStore.reportComment
      },
      set(val) {
        this.chatStore.reportComment = val
      }
    },
    errors() {
      return this.chatStore.errors
    },
  },
  methods : {
    report() {
      this.chatStore.report()
    }
  },
})
</script>
<style lang="scss" scoped>
  .popup-content-form-btnWrap button {
    margin: 0 20px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
