import i18n from '@/language/i18n'
import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const InspectionAuctionRequest = defineStore('inspectionAuctionRequest', {
  state : () => {
    return {
      locale             : 'ja',
      constants          : {},
      postCode           : '',
      address            : '',
      comment            : '',
      errors             : {},
      detailData         : [],
      auctionData        : [],

      selectItemRequestNo    : null,
      selectManageNo         : '',
      requestCategoryId      : '',
      selectExhibitionItemNo : null,

      requestFlag        : true,
      completedFlag      : false,
      isOpenDialog       : false,
      popupAuctionDialog : false,
    }
  },
  getters : {
  },
  actions : {
    resetData() {
      loadingStore.setLoading(true)
      this.constants = {}
      this.comment = ''
      this.errors = {}
      this.requestFlag = true
      this.completedFlag = false
      this.isOpenDialog = false
      this.popupAuctionDialog = false
      this.getConstants()
      loadingStore.setLoading(false)
    },
    getConstants() {
      Methods.apiExcute('private/get-request-auction-constants', this.locale).then(data => {
        this.postCode = data.member.postCode
        this.address = data.member.address
        this.constants = {
          EXHIBIT_FEE        : data.constants ? data.constants.filter(x => x.key_string === 'EXHIBIT_FEE') : null,
          EXHIBIT_FEE_AGENCY : data.constants ? data.constants.filter(x => x.key_string === 'EXHIBIT_FEE_AGENCY') : null,
        }
      })
        .catch(error => {
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    auction() {
      this.shipValidate()
        .then(() => {
          if (Object.keys(this.errors).length === 0) {
            loadingStore.setLoading(true)
            console.log('auction')
            this.errors = {}
            this.detailData = []
            this.popupAuctionDialog = true
            Methods.apiExcute('private/get-recent-auction', 'ja').then(data => {
              if (typeof data === 'undefined' || data === null) {
                this.detailData = []
              } else {
                this.detailData = data
              }
              loadingStore.setLoading(false)
            })
              .catch(error => {
                loadingStore.setLoading(false)
                this.errors = Methods.parseHtmlResponseError(router, error)
              })
          }
        })
    },
    requestAuction(index) {
      this.auctionData = this.detailData[index]
      this.requestFlag = false
      this.completedFlag = false
    },
    openRequestComfirmDialog() {
      this.isOpenDialog = true
    },
    closeRequestComfirmDialog() {
      this.isOpenDialog = false
    },
    requestItem(locale = 'ja') {
      this.errors = {}
      const params = {
        selectManageNo    : this.selectManageNo,
        comment           : this.comment,
        exhibitionNo      : this.requestCategoryId === '4' ? this.auctionData.exhibition_no : null,
        requestCategoryId : this.requestCategoryId,
        postCode          : this.postCode,
        address           : this.address
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('private/request-exhibit-item', locale, params).then(data => {
        loadingStore.setLoading(false)
        this.requestFlag = false
        this.completedFlag = true
        this.isOpenDialog = false
        this.popupAuctionDialog = false
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
          // This.isOpenDialog = false
        })
    },
    agency() {
      this.shipValidate()
        .then(() => {
          if (Object.keys(this.errors).length === 0) {
            this.requestFlag = false
            this.completedFlag = false
          }
        })
    },
    shipValidate() {
      this.errors = {}
      if (typeof this.postCode === 'undefined' || this.postCode === null || this.postCode === '') {
        this.errors.postCode = i18n.t('INSPECTION_AUCTION_POST_CODE_REQUIRED_ERROR_MESSAGE')
      }
      if (typeof this.address === 'undefined' || this.address === null || this.address === '') {
        this.errors.address = i18n.t('INSPECTION_AUCTION_ADDRESS_REQUIRED_ERROR_MESSAGE')
      }

      if (Object.keys(this.errors).length === 0) {
        const params = {
          selectManageNo    : this.selectManageNo,
          comment           : this.comment,
          exhibitionNo      : this.requestCategoryId === '4' ? this.auctionData.exhibition_no : null,
          requestCategoryId : this.requestCategoryId,
          postCode          : this.postCode,
          address           : this.address,
          validateFlag      : true
        }
        loadingStore.setLoading(true)
        return Methods.apiExcute('private/request-exhibit-item', this.locale, params)
          .then(data => {
            loadingStore.setLoading(false)
            return Promise.resolve()
          })
          .catch(error => {
            loadingStore.setLoading(false)
            this.errors = Methods.parseHtmlResponseError(router, error)
            return Promise.resolve()
          })
      }
      return Promise.resolve()
    },
    getExhibitBaseFee(price, requestCategoryId) {
      let tmpBase = null
      let FEE_CONSTANT = null
      if (String(requestCategoryId) === '3') {
        // 出品代行
        FEE_CONSTANT = this.constants.EXHIBIT_FEE_AGENCY
      } else if (String(requestCategoryId) === '4') {
        // 自分で出品
        FEE_CONSTANT = this.constants.EXHIBIT_FEE
      }
      if (FEE_CONSTANT) {
        FEE_CONSTANT.map(pitch => {
          // Low
          const low = typeof pitch.value3 === 'undefined' || pitch.value3 === null || pitch.value3.length === 0
                     || (price >= Number(pitch.value3))
          // High
          const high = typeof pitch.value4 === 'undefined' || pitch.value4 === null || pitch.value4.length === 0
                     || (price < Number(pitch.value4))
          if (low && high) {
            tmpBase = pitch
          }
        })
      }
      return tmpBase
    },
    searchPostCode() {
      const params = {
        postCode : this.postCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.address = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
  },
})

export default InspectionAuctionRequest
