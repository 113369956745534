import i18n from '@/language/i18n'
import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const useChat = defineStore('useChat', {
  state : () => {
    return {
      locale                 : 'ja',
      selectManageNo         : null,
      chatName               : null,
      selectItemRequestNo    : null,
      selectExhibitionItemNo : null,
      exhibitionMemberFlag   : false,
      answerFlg              : false,
      agencyFlg              : false,
      loginMemberNo          : null,
      inputMessage           : null,
      selectMessageNo        : null,
      selectBadMemberNo      : null,
      reportComment          : null,
      isOpenDialog           : false,
      bidChatFlg             : false,
      chatMessageData        : [],
      requestItem             : {
        manageNo  : null,
        maker     : null,
        makerName : null,
        bikeType  : null,
        delivery  : null
      },
      constants              : {},
      errors                 : {},
    }
  },
  getters : {
  },
  actions : {
    memberCheck(paramId, chatName) {
      let api_name = ''
      let params = {}
      if (chatName === 'request') {
        api_name = 'private/check-member-for-request-item'
        params = {item_request_no : paramId}
      } else if (chatName === 'bid') {
        api_name = 'private/check-member-for-exhibition-result'
        params = {exhibition_item_no : paramId}
      }
      return Methods.apiExcute(api_name, this.locale, params).then(data => {
        if (data) {
          if (data[0].member_flag === true) {
            return Promise.resolve()
          }
        }
        return Promise.reject(new Error('Invalid page request'))
      })
    },
    reset(paramId, chatName) {
      this.chatMessageData = {}
      this.inputMessage = null
      this.loginMemberNo = null
      this.answerFlg = false
      this.agencyFlg = false
      this.exhibitionMemberFlag = false
      this.requestItem = {
        manageNo  : null,
        maker     : null,
        makerName : null,
        bikeType  : null
      }
      this.errors = {}
      this.chatName = chatName
      this.bidChatFlg = false

      return Promise.resolve()
        .then(() => {
          if (chatName === 'request') {
            this.selectItemRequestNo = paramId
            return this.getRequestItem()
          } else if (chatName === 'inquiry') {
            this.selectExhibitionItemNo = paramId
            this.exhibitionMemberFlag = false
            this.answerFlg = false
            return this.getExhibitionItem()
          } else if (chatName === 'bid') {
            this.selectExhibitionItemNo = paramId
            return this.getExhibitionResultItem()
          } else {
            return Promise.resolve()
          }
        })
        .then(() => {
          return this.getChatMessage()
        })
    },
    getRequestItem() {
      return Methods.apiExcute('private/get-request-item-for-chat', this.locale, {
        item_request_no : this.selectItemRequestNo
      }).then(data => {
        const requestItem = data && data.item ? data.item : {}
        this.loginMemberNo = data.member_no
        this.requestItem.manageNo = requestItem.manage_no
        this.requestItem.maker = requestItem.maker
        this.requestItem.makerName = requestItem.maker_name
        this.requestItem.bikeType = requestItem.bike_type
        this.selectManageNo = requestItem.manage_no
        return Promise.resolve()
      })
    },
    getExhibitionItem() {
      return Methods.apiExcute('public/get-exhibition-item-for-chat', this.locale, {
        exhibition_item_no : this.selectExhibitionItemNo
      }).then(data => {
        let exhibitItem = {}
        if (data) {
          exhibitItem = data.item
        }
        if (exhibitItem.item_no) {
          if (exhibitItem.exhibition_member_no) {
            if (exhibitItem.exhibition_division === 1 && exhibitItem.exhibition_member_flag === true) {
              this.exhibitionMemberFlag = true
              this.answerFlg = true
            } else if (exhibitItem.exhibition_division === 2 && exhibitItem.exhibition_member_flag === true) {
              this.agencyFlg = true
              this.answerFlg = true
            }
          }
          this.loginMemberNo = data.member_no
          this.requestItem.manageNo = exhibitItem.manage_no
          this.requestItem.maker = exhibitItem.maker
          this.requestItem.makerName = exhibitItem.maker_name
          this.requestItem.bikeType = exhibitItem.bike_type
          return Promise.resolve()
        }
        return Promise.resolve()
      })
    },
    getExhibitionResultItem() {
      return Methods.apiExcute('private/get-exhibition-result-for-chat', this.locale, {
        exhibition_item_no : this.selectExhibitionItemNo
      }).then(data => {
        const resultItem = data && data.item ? data.item : {}
        this.loginMemberNo = data.member_no
        if (resultItem) {
          this.requestItem.manageNo = resultItem.manage_no
          this.requestItem.makerName = resultItem.maker_name
          this.requestItem.bikeType = resultItem.bike_type
          this.requestItem.delivery = resultItem.delivery_selected_name
          this.exhibitionMemberFlag = resultItem.exhibition_member_flag
          this.bidChatFlg = true
        }
        return Promise.resolve()
      })
    },
    getChatMessage() {
      loadingStore.setLoading(true)
      this.errors = {}
      let api_name = ''
      let params = {}
      if (this.chatName === 'request') {
        api_name = 'private/get-chat-request'
        params = {manage_no : this.selectManageNo}
      } else if (this.chatName === 'inquiry') {
        api_name = 'public/get-chat-inquiry'
        params = {exhibition_item_no : this.selectExhibitionItemNo}
      } else if (this.chatName === 'bid') {
        api_name = 'private/get-chat-bid'
        params = {exhibition_item_no : this.selectExhibitionItemNo}
      }
      return Methods.apiExcute(api_name, this.locale, params).then(data => {
        let cnt = 0
        this.chatMessageData = (data || []).map(x => {
          if (x.update_category_id === '2') cnt++
          return {
            ...x,
            cnt
          }
        })
        loadingStore.setLoading(false)

        process.nextTick(() => {
          const childNodes = document.getElementById('chat-area').childNodes
          if (childNodes && childNodes.length > 0) {
            childNodes[childNodes.length - 1].scrollIntoView()
          }
        })

        return Promise.resolve()
      })
    },
    regist(event) {
      event.preventDefault()
      if (this.loginMemberNo) {
        if (this.agencyFlg === false) {
          if (this.inputMessage) {
            loadingStore.setLoading(true)
            let api_name = ''
            let params = {}
            if (this.chatName === 'request') {
              api_name = 'private/request-chat-message'
              params = {
                manageNo : this.selectManageNo,
                message  : this.inputMessage
              }
            } else if (this.chatName === 'inquiry') {
              api_name = 'private/request-chat-inquiry-message'
              params = {
                exhibitionItemNo          : this.selectExhibitionItemNo,
                updateCategoryId          : this.exhibitionMemberFlag === true ? '1' : '2',
                answerExhibitionMessageNo : this.exhibitionMemberFlag === true ? this.selectMessageNo : null,
                message                   : this.inputMessage
              }
            } else if (this.chatName === 'bid') {
              api_name = 'private/request-chat-bid-message'
              params = {
                exhibitionItemNo : this.selectExhibitionItemNo,
                updateCategoryId : this.exhibitionMemberFlag === true ? '1' : '2',
                message          : this.inputMessage
              }
            }
            Methods.apiExcute(api_name, this.locale, params)
              .then(data => {
                this.errors = {}
                this.inputMessage = null
                loadingStore.setLoading(false)
                if (this.exhibitionMemberFlag === true) {
                  this.answerFlg = true
                }
                this.getChatMessage()
              })
              .catch(error => {
                loadingStore.setLoading(false)
                this.errors = Methods.parseHtmlResponseError(router, error)
              })
          } else {
            this.errors = i18n.t('CHAT_MESSAGE_REQUIRED_ERROR_MESSAGE')
          }
        } else {
          this.errors = i18n.t('CHAT_MESSAGE_AGENCY_ERROR_MESSAGE')
        }
      } else {
        this.errors = i18n.t('CHAT_MESSAGE_LOGIN_ERROR_MESSAGE')
      }
    },
    openReportComfirmDialog() {
      this.isOpenDialog = true
    },
    closeReportComfirmDialog() {
      this.isOpenDialog = false
    },
    report() {
      const params = {
        exhibitionItemNo    : this.selectExhibitionItemNo,
        chatCategoryId      : this.chatName === 'inquiry' ? 1 : 2,
        messageNo           : this.selectMessageNo,
        badMemberNo         : this.selectBadMemberNo,
        reportComment       : this.reportComment
      }
      Methods.apiExcute('private/request-report-chat-message', this.locale, params)
        .then(data => {
          this.errors = {}
          this.selectMessageNo = null
          this.selectBadMemberNo = null
          this.reportComment = null
          this.isOpenDialog = false
          loadingStore.setLoading(false)
          if (this.exhibitionMemberFlag === true) {
            this.answerFlg = true
          }
          this.getChatMessage()
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    checkedMessage() {
      let api_name = ''
      let params = {}
      loadingStore.setLoading(true)
      if (this.chatName === 'request') {
        api_name = 'private/update-request-message'
        params = {
          requestMessageNo : this.selectMessageNo
        }
      } else if (this.chatName === 'bid') {
        api_name = 'private/update-bid-message'
        params = {
          successfullMessageNo : this.selectMessageNo
        }
      }
      Methods.apiExcute(api_name, this.locale, params)
        .then(data => {
          this.errors = {}
          this.selectMessageNo = null
          loadingStore.setLoading(false)
          this.getChatMessage()
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    }
  }
})

export default useChat
