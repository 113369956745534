import {isNaN} from 'lodash'
import moment from 'moment'
moment.locale('ja')

export default {
  filters : {
    dateTimeDayFormat(date, format = 'YYYY年M月D日（ddd）H:mm') {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format(format)
      return year
    },
    dateTimeFormat(date, format = 'YYYY年M月D日 H:mm') {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format(format)
      return year
    },
    dateFormat(date) {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format('YYYY年M月D日')
      return year
    },
    timeFormat(date, format = 'H:mm') {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const time = moment(isoYear).format(format)
      return time
    },
    // 総賞金、地方・中央賞金
    prizeMoney2String(val) {
      if (val && !isNaN(val)) {
        const tmp = Number(val).toFixed(1)
        return Number.isInteger(Number(tmp)) ? Number(tmp).toLocaleString() : Number(tmp).toLocaleString()
      } else {
        return (val || '')
      }
    },
    // 値が「-」(数値以外)や空文字の場合は単位を表示しないようにする
    number2string(val, nullText = '', unit = '') {
      if (typeof val === 'undefined' || val === null || String(val).length === 0) {
        return nullText
      }
      return isNaN(val) ? String(val) : (val.numberString() + unit)
    },
    addComma(val1) {
      if (typeof val1 === 'undefined' || val1 === null || val1 === '') {
        return ''
      } else if (isNaN(val1)) {
        return val1
      } else {
        return Number(val1).toLocaleString()
      }
    },
    subComma(val1) {
      if (typeof val1 === 'undefined' || val1 === null || val1 === '') {
        return ''
      } else {
        // This.requestResultData.assessment_amount = String(val1).replace(/,/g, '')
        return String(val1).replace(/,/g, '')
      }
    },
    getFullImagePath(file_path) {
      if (!file_path) return null
      return `${process.env.VUE_APP_API_ENDPOINT.replace('/api/', '')}/${encodeURIComponent(file_path)}`
    },
    getCubicText(val, nullText = '') {
      if (typeof val === 'undefined' || val === null || String(val).length === 0) {
        return nullText
      }
      if (String(Number(val)) === 'NaN') {
        if (String(val).indexOf('cc') === -1) {
          return `${String(val)}cc`
        } else {
          return String(val)
        }
      } else {
        return (`${val.numberString()}cc`)
      }
    },
    escapeHtml(val) {
      if (val) {
        return val
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/&/g, '&amp;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#39;')
      }
      return null
    }
  }
}
